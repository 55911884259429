.section-primary {
    padding: 50px;
}

.flashcard-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    max-width: 1200px;
}

.flashcard {
    background-color: #fff;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0 15px 15px rgba(93, 195, 180, 0.2);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.flashcard:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.flashcard-image {
    margin-bottom: 20px;
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flashcard-image img {
    width: auto;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.flashcard-image img:hover {
    transform: scale(1.05);
}

.flashcard h3 {
    margin-bottom: 15px;
    font-size: 22px;
}

.flashcard p {
    font-size: 16px;
    color: #666;
}

.section-heading {
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .flashcard {
        width: 100%;
        max-width: none;
    }
}

.resume-download {
    display: inline-block;
    padding: 10px 20px;
    background-color: transparent;
    color: inherit;
    text-decoration: none;
    border: 2px solid lightblue;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-top: 40px;
}

.resume-download:hover {
    background-color: lightblue;
    color: white;
}

.section-heading h2 {
    transition: color 0.3s ease;
}

.section-heading h2:hover {
    color: lightblue;
}
